<template>
    <div class="analysis-assess-base">
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <router-view class="analysis-assess-view"></router-view>
    </div>
</template>
<script>
export default {
    name: "assess_result",
    data() {
        return {
            itemName: '评估结果',
            breadlist: [
                {
                    path: '',
                    name: '分析评估'
                }
            ],
        }
    }
}
</script>
<style lang="scss" scoped>
.analysis-assess-base {
    width: 100%;
    height: 100%;
    background: #f7f9f4;
    .analysis-assess-view {
        width: 100%;
        height: calc(100% - 45px);
    }
}
</style>